.TimeBlock__container {
    width: fit-content;
    background: #fff;
    border-radius: 8px;
    position: absolute;
    z-index: 9999;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes TimeBlock__container--popup {
    from {
        opacity: 0;
        transform: translateY(100%) translateX(-50%);
    }
    to {
        opacity: 1;
        transform: translateY(0) translateX(-50%);
    }
}

@keyframes TimeBlock__container--popdown {
    from {
        opacity: 1;
        transform: translateY(0) translateX(-50%);
    }
    to {
        opacity: 0;
        transform: translateY(100%) translateX(-50%);
    }
}