.FullScreenVideo {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.FullScreenVideo__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.FullScreenVideo__content {
    position: relative;
    z-index: 1001;
    max-width: 90%;
    max-height: 90%;
}

.FullScreenVideo__close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 2rem;
    color: white;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1002;
}
